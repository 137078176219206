/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import moment from 'moment';
import { Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import userDefaultImage from '../../../../../assets/images/pages/shared/user-default.png';
import { LocaleContext } from '../../../../../contexts/localeContext';
import { LoadingContext } from '../../../../../contexts/loadingContext';
import {
  isLoading,
  isNotLoading,
} from '../../../../../reducers/loadingReducers';
import {
  Icon,
  ShortText,
  Button,
  Link,
} from '../../../../general';
import { dateDiff, yearsToMonthsParser } from '../../../../../helpers/parser';
import { setFavoriteSeeker } from '../../../../../api/v1/post/company';
import {
  cardContainerStyles,
  cardStyles,
  imageStyles,
  rightCardStyles,
  nameLocationAndFinalScoreContainerStyles,
  nameAndLocationContainerStyles,
  nameStyles,
  iconAndLocationContainerStyles,
  iconStyles,
  locationStyles,
  locationAndJobExperienceSeparatorStyles,
  jobExperienceStyles,
  badgeAndFinalScoreContainerStyles,
  finalScoreContainerStyles,
  finalScoreStyles,
  // badgeStyles,
  skillsContainerStyles,
  jobNameStyles,
  skillPillsContainerStyles,
  skillPillStyles,
  skillNameStyles,
  workExperienceAndButtonContainerStyles,
  educationlabelAndValueContainerStyles,
  workExperiencelabelAndValueContainerStyles,
  labelStyles,
  workExperienceContainerStyles,
  valueContainerStyles,
  workExperienceValueStyles,
  valueStyles,
  dotIconStyles,
  dateValueStyles,
  presentTextStyles,
  buttonContainerStyles,
  inviteUserButtonStyles,
  addUserIconStyles,
  favoriteButtonStyles,
  heartIconStyles,
} from './ApplicantCard.styles';

const ApplicantCard = ({
  data,
  setInviteUserModalStatus,
  setRefetchStatus,
}) => {
  const history = useHistory();
  const { locale } = useContext(LocaleContext);
  const { dispatch } = useContext(LoadingContext);

  const badgeChecker = (score) => {
    if (score >= 95) {
      return {
        badge: 'teacher\'s award',
        color: 'linear-gradient(75.19deg, #E12179 -8.71%, #311996 108.25%)',
        textColor: '#311996',
      };
    }

    if (score >= 90 && score < 95) {
      return {
        badge: 'honors',
        color: 'linear-gradient(219.89deg, #40a4ff -2.35%, #3e62bd 72.83%)',
        textColor: '#3e62bd',
      };
    }

    if (score >= 80 && score < 90) {
      return {
        badge: 'graduate',
        color: '#f39222',
        textColor: '#f39222',
      };
    }

    if (score >= 70 && score < 80) {
      return {
        badge: 'participant',
        color: 'linear-gradient(80.89deg, #999999 -8.71%, #E5E5E5 200.83%)',
        textColor: '#999999',
      };
    }

    return null;
  };

  const handleOpenInviteUserModal = () => {
    setInviteUserModalStatus({
      seekerId: data.id,
      status: true,
    });
  };

  const handleFavorite = async () => {
    try {
      const favoriteData = {
        seeker_id: data.id,
        is_favorite: !data.is_favorite,
      };

      dispatch(isLoading());
      await setFavoriteSeeker(favoriteData);
      setRefetchStatus(true);
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        history.replace(`/${locale}/login`);
      }

      if (error.response.status >= 500) {
        history.replace(`/${locale}/error-500`);
      }
    } finally {
      dispatch(isNotLoading());
    }
  };

  return (
    <div css={cardContainerStyles}>
      <div css={cardStyles}>
        <img
          src={data.profile_image_url || userDefaultImage}
          css={imageStyles}
          alt="avatar"
        />
        <div css={rightCardStyles}>
          <div css={nameLocationAndFinalScoreContainerStyles}>
            <div css={nameAndLocationContainerStyles}>
              <ShortText styles={nameStyles}>{data.name}</ShortText>
              <div css={iconAndLocationContainerStyles}>
                <Icon name="map-pin-line" styles={iconStyles} />
                <ShortText styles={locationStyles}>
                  {`${data.city ? data.city.label : '-'}, ${
                    data.country ? data.country.label : '-'
                  }`}
                </ShortText>
                <div css={locationAndJobExperienceSeparatorStyles} />
                <ShortText styles={jobExperienceStyles}>
                  {yearsToMonthsParser(data.years_of_work_experience_track_records)}
                </ShortText>
              </div>
            </div>
            <div css={badgeAndFinalScoreContainerStyles}>
              {data.last_hacktiv_course_track_record !== null
                && data.last_hacktiv_course_track_record.score >= 65 && (
                  <Fragment>
                    <div
                      css={finalScoreContainerStyles(
                        badgeChecker(
                          data.last_hacktiv_course_track_record.score,
                        ).color,
                      )}
                    >
                      <ShortText styles={finalScoreStyles} fontSize={24}>
                        {Math.floor(
                          data.last_hacktiv_course_track_record.score,
                        )}
                      </ShortText>
                    </div>
                    {/* <ShortText
                      styles={badgeStyles(
                        badgeChecker(
                          data.last_hacktiv_course_track_record.score,
                        ).color,
                      )}
                      fontSize={18}
                    >
                      {
                        badgeChecker(
                          data.last_hacktiv_course_track_record.score,
                        ).badge
                      }
                    </ShortText> */}
                  </Fragment>
              )}
            </div>
          </div>
          <div css={skillsContainerStyles}>
            <ShortText styles={jobNameStyles}>
              {
                data.role_preferences.map(({ label }, i) => {
                  if (i === data.role_preferences.length - 1) {
                    return ` ${label}`;
                  }
                  return ` ${label} |`;
                })
              }
            </ShortText>
            <div css={skillPillsContainerStyles}>
              {data.skills.slice(0, 4).map(({ label }) => (
                <div key={label} css={skillPillStyles}>
                  <ShortText styles={skillNameStyles}>{label}</ShortText>
                </div>
              ))}
              {data.skills.length > 4 && (
                <div css={skillPillStyles}>
                  <ShortText styles={skillNameStyles}>
                    {`+${data.skills.length - 4}`}
                  </ShortText>
                </div>
              )}
            </div>
          </div>
          <div css={educationlabelAndValueContainerStyles}>
            <ShortText styles={labelStyles}>education</ShortText>
            <div css={valueContainerStyles}>
              <ShortText styles={valueStyles}>
                {data.track_records.formal_education.length !== 0
                  && data.track_records.formal_education[
                    data.track_records.formal_education.length - 1
                  ].institution_name}
              </ShortText>
              <Icon name="checkbox-blank-circle-fill" styles={dotIconStyles} />
              <ShortText styles={dateValueStyles}>
                {data.track_records.formal_education.length !== 0
                  && data.track_records.formal_education[
                    data.track_records.formal_education.length - 1
                  ].date_until
                  && `Graduated in ${moment(
                    data.track_records.formal_education[
                      data.track_records.formal_education.length - 1
                    ].date_until,
                  ).format('YYYY')}`}
              </ShortText>
            </div>
          </div>
          <div css={workExperienceAndButtonContainerStyles}>
            <div css={workExperiencelabelAndValueContainerStyles}>
              <ShortText styles={labelStyles}>work</ShortText>
              <div css={workExperienceContainerStyles}>
                {(data.track_records.work_experience)
                  .slice(0, 2)
                  .map((d) => (
                    <div
                      key={d.id}
                      css={[valueContainerStyles, workExperienceValueStyles]}
                    >
                      <ShortText styles={valueStyles}>
                        {d.institution_name}
                      </ShortText>
                      <Icon
                        name="checkbox-blank-circle-fill"
                        styles={dotIconStyles}
                      />
                      <ShortText styles={dateValueStyles}>
                        {dateDiff(d.date_from, d.date_until ? d.date_until : new Date())}
                      </ShortText>
                      {
                        !d.date_until && (
                          <div>
                            &nbsp;
                            <ShortText>
                              -
                            </ShortText>
                            &nbsp;
                            <ShortText styles={presentTextStyles}>
                              present
                            </ShortText>
                          </div>
                        )
                      }
                    </div>
                  ))}
              </div>
            </div>
            <div css={buttonContainerStyles}>
              <Link to={`/${locale}/job/resume/applicant/${data.id}`}>
                <Button type="button" size="S" color="LIGHT_BLUE" inverted>
                  details
                </Button>
              </Link>
              <Button
                type="button"
                styles={inviteUserButtonStyles}
                size="M"
                color="LIGHT_BLUE"
                onClick={handleOpenInviteUserModal}
              >
                invite user&nbsp;
                <Icon name="user-add-line" styles={addUserIconStyles} />
              </Button>
              <button
                type="button"
                css={favoriteButtonStyles(data.is_favorite)}
                onClick={handleFavorite}
              >
                <Icon
                  name={data.is_favorite ? 'heart-fill' : 'heart-line'}
                  styles={heartIconStyles(data.is_favorite)}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ApplicantCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    profile_image_url: PropTypes.string,
    name: PropTypes.string,
    years_of_work_experience_track_records: PropTypes.number,
    last_hacktiv_course_track_record: PropTypes.shape({
      score: PropTypes.number,
    }),
    city: PropTypes.shape({
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      label: PropTypes.string,
    }),
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    track_records: PropTypes.shape({
      formal_education: PropTypes.arrayOf(
        PropTypes.shape({
          institution_name: PropTypes.string,
          date_until: PropTypes.string,
        }),
      ),
      work_experience: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    role_preferences: PropTypes.arrayOf([
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ]),
    is_favorite: PropTypes.bool,
  }).isRequired,
  setInviteUserModalStatus: PropTypes.func.isRequired,
  setRefetchStatus: PropTypes.func.isRequired,
};

export default ApplicantCard;
