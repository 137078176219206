/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import moment from 'moment';
import { useContext, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ExperienceList from './ExperienceList/ExperienceList';
import CertificateList from './CertificateList/CertificateList';
import ProjectList from './ProjectList/ProjectList';
import {
  Container,
  ShortText,
  Icon,
  ExternalLink,
  Button,
  Text,
} from '../../../general';
import userDefaultImage from '../../../../assets/images/pages/shared/user-default.png';
import { updateJobApplications } from '../../../../api/v1/put';
import { LoadingContext } from '../../../../contexts/loadingContext';
import { isLoading, isNotLoading } from '../../../../reducers/loadingReducers';
import { useDetectOutsideClick } from '../../../../hooks';
import { LocaleContext } from '../../../../contexts/localeContext';
import {
  containerStyles,
  cardStyles,
  upperCardStyles,
  imageStyles,
  detailsContainerStyles,
  nameStyles,
  iconAndLocationContainerStyles,
  iconStyles,
  locationStyles,
  phoneNumberStyles,
  phoneIconStyles,
  socialMediaAndBadgeContainerStyles,
  socialMediaContainerStyles,
  socialMediaLinkStyles,
  socialMediaIconStyles,
  badgeAndFinalScoreContainerStyles,
  finalScoreContainerStyles,
  finalScoreStyles,
  // badgeStyles,
  statusContainerStyles,
  studentInfoRowStyles,
  studentInfoLabelAndValue,
  labelAndValueContainerStyles,
  labelStyles,
  valueStyles,
  skillsContainerStyles,
  skillPillContainerStyles,
  skillPillStyles,
  skillStyles,
  buttonContainerStyles,
  applicationDropdownContainerStyles,
  applicationButtonStyles,
  downArrowIconStyles,
  applicationDropdownStyles,
  buttonListStyles,
  dropdownDivider,
  rejectButtonStyles,
  buttonLinkStyles,
  lowerCardStyles,
  instructorRemarksCardContainerStyles,
  instructorRemarksCardStyles,
  remarkTitleStyles,
  quillIconStyles,
  remarkDescriptionStyles,
  workExperienceAndEducationContainerStyles,
  titleStyles,
  professionalSummaryStyles,
  columnStyles,
} from './Details.styles';
import { SnackbarContext } from '../../../../contexts/snackbarContext';
import { isShow } from '../../../../reducers/snackbarReducers';

const Details = ({ applicantData }) => {
  const history = useHistory();
  const applicationDropdownRef = useRef();
  const { jobId } = useParams();
  const { dispatch } = useContext(LoadingContext);
  const { dispatch: dispatchNotif } = useContext(SnackbarContext);
  const { locale } = useContext(LocaleContext);
  const [isApplicationDropdownShow, setApplicationDropdownStatus] = useState(false);

  const applicationOptions = [
    {
      label: 'applicant',
      value: 'applicant',
    },
    {
      label: 'shortlisted',
      value: 'shortlisted',
    },
    {
      label: 'HR Interview',
      value: 'hr-interview',
    },
    {
      label: 'Technical Stage',
      value: 'technical-stage',
    },
    {
      label: 'offer',
      value: 'offer',
    },
    {
      label: 'hired',
      value: 'hired',
    },
  ];

  const applicationRejectedOptions = [
    {
      label: 'rejected - education',
      value: 'rejected-education',
    },
    {
      label: 'rejected - tech stack',
      value: 'rejected-tech-stack',
    },
    {
      label: 'rejected - candidate withdraw',
      value: 'rejected-candidate-withdraw',
    },
    {
      label: 'rejected - experience',
      value: 'rejected-experience',
    },
    {
      label: 'rejected - aptitude',
      value: 'rejected-aptitude',
    },
    {
      label: 'rejected - technical',
      value: 'rejected-technical',
    },
    {
      label: 'rejected - did not submit',
      value: 'rejected-did-not-submit',
    },
    {
      label: 'rejected - communication',
      value: 'rejected-communication',
    },
    {
      label: 'rejected - culture/personality',
      value: 'rejected-culture/personality',
    },
    {
      label: 'rejected - no response',
      value: 'rejected-no-response',
    },
    {
      label: 'rejected - salary',
      value: 'rejected-salary',
    },
  ];

  const badgeChecker = (score) => {
    if (score >= 95) {
      return {
        badge: 'teacher\'s award',
        color: 'linear-gradient(75.19deg, #E12179 -8.71%, #311996 108.25%)',
        textColor: '#311996',
      };
    }

    if (score >= 90 && score < 95) {
      return {
        badge: 'honors',
        color: 'linear-gradient(219.89deg, #40a4ff -2.35%, #3e62bd 72.83%)',
        textColor: '#3e62bd',
      };
    }

    if (score >= 80 && score < 90) {
      return {
        badge: 'graduate',
        color: '#f39222',
        textColor: '#f39222',
      };
    }

    if (score >= 70 && score < 80) {
      return {
        badge: 'participant',
        color: 'linear-gradient(80.89deg, #999999 -8.71%, #E5E5E5 200.83%)',
        textColor: '#999999',
      };
    }

    return null;
  };

  const contactFinder = (channel) => {
    const contact = applicantData.contacts.find((d) => d.channel === channel);

    if (contact) return contact.value;

    return null;
  };

  useDetectOutsideClick(applicationDropdownRef, () => {
    if (isApplicationDropdownShow) setApplicationDropdownStatus(false);
  });

  const handleApplicationDropdownToggle = () => {
    setApplicationDropdownStatus((prevState) => !prevState);
  };

  const handleUpdateJobApplication = async (seekerId, status) => {
    const jobApplicationsData = {
      seeker_ids: [seekerId],
      to_status: status,
    };

    try {
      dispatch(isLoading());
      const response = await updateJobApplications(jobId, jobApplicationsData);
      if (response.status === 200) {
        dispatchNotif(isShow('success', response.data.message));
      }
    } catch (error) {
      if (error.response.status === 422) {
        dispatchNotif(isShow('error', 'error to update'));
      }
      if (error.response.status === 401 || error.response.status === 403) {
        history.replace(`/${locale}/login`);
      }

      if (error.response.status >= 500) {
        history.replace(`/${locale}/error-500`);
      }
    } finally {
      dispatch(isNotLoading());
      setApplicationDropdownStatus(false);
    }
  };

  return (
    <Container contentWidthType="FULL" containerStyles={containerStyles}>
      <div css={[cardStyles, upperCardStyles]}>
        <img
          src={applicantData.profile_image_url || userDefaultImage}
          css={imageStyles}
          alt={applicantData.name}
        />
        <div css={detailsContainerStyles}>
          <ShortText styles={nameStyles}>{applicantData.name}</ShortText>
          <div css={iconAndLocationContainerStyles}>
            <Icon name="map-pin-line" styles={iconStyles} />
            {
              applicantData.city?.label && (
                <ShortText styles={locationStyles}>
                  {`${applicantData.city?.label}, ${applicantData.country?.label}`}
                </ShortText>
              )
            }
          </div>
          <ExternalLink href={`tel:${contactFinder('phone_number')}`} styles={phoneNumberStyles}>
            <Icon name="phone-fill" styles={phoneIconStyles} />
            {contactFinder('phone_number')}
          </ExternalLink>
          <div css={socialMediaAndBadgeContainerStyles}>
            <div css={socialMediaContainerStyles}>
              {contactFinder('linkedin') && (
                <ExternalLink
                  href={contactFinder('linkedin')}
                  styles={socialMediaLinkStyles}
                >
                  <Icon
                    name="linkedin-box-fill"
                    styles={socialMediaIconStyles}
                  />
                </ExternalLink>
              )}
              {contactFinder('github') && (
                <ExternalLink
                  href={contactFinder('github')}
                  styles={socialMediaLinkStyles}
                >
                  <Icon name="github-fill" styles={socialMediaIconStyles} />
                </ExternalLink>
              )}
              {/* <ExternalLink
                href="https://linkedin.com"
                styles={socialMediaLinkStyles}
              >
                <Icon name="link-m" styles={socialMediaIconStyles} />
              </ExternalLink> */}
            </div>
            {applicantData.last_hacktiv_course_track_record && (
              <div css={badgeAndFinalScoreContainerStyles}>
                <div
                  css={finalScoreContainerStyles(
                    badgeChecker(
                      applicantData.last_hacktiv_course_track_record?.score,
                    ).color,
                  )}
                >
                  <ShortText styles={finalScoreStyles} fontSize={24}>
                    {applicantData.last_hacktiv_course_track_record?.score || 0}
                  </ShortText>
                </div>
                {/* <ShortText
                  styles={badgeStyles(
                    badgeChecker(
                      applicantData.last_hacktiv_course_track_record?.score,
                    ).color,
                  )}
                  fontSize={18}
                >
                  {
                    badgeChecker(
                      applicantData.last_hacktiv_course_track_record?.score,
                    ).badge
                  }
                </ShortText> */}
              </div>
            )}
          </div>
        </div>
        <div css={statusContainerStyles}>
          {applicantData.last_hacktiv_course_track_record ? (
            <div>
              <div css={studentInfoRowStyles}>
                <div
                  css={[labelAndValueContainerStyles, studentInfoLabelAndValue]}
                >
                  <ShortText styles={labelStyles}>worked as</ShortText>
                  <ShortText styles={valueStyles}>
                    {applicantData.track_records.work_experience.length !== 0
                      ? applicantData.track_records.work_experience[0]?.title
                      : '-'}
                  </ShortText>
                </div>
                <div
                  css={[labelAndValueContainerStyles, studentInfoLabelAndValue]}
                >
                  <ShortText styles={labelStyles}>studied</ShortText>
                  <ShortText styles={valueStyles}>
                    {applicantData.track_records.formal_education.length !== 0
                      ? applicantData.track_records.formal_education[0]?.field_of_study?.label
                      : '-'}
                  </ShortText>
                </div>
              </div>
              <div css={studentInfoRowStyles}>
                <div
                  css={[labelAndValueContainerStyles, studentInfoLabelAndValue]}
                >
                  <ShortText styles={labelStyles}>team name</ShortText>
                  <ShortText styles={valueStyles}>
                    {applicantData.last_batch
                      ? applicantData.last_batch.program_name
                      : '-'}
                  </ShortText>
                </div>
                <div
                  css={[labelAndValueContainerStyles, studentInfoLabelAndValue]}
                >
                  <ShortText styles={labelStyles}>graduate</ShortText>
                  <ShortText styles={valueStyles}>
                    {applicantData.last_batch
                      ? moment(applicantData.last_batch.graduation_date).format(
                        'DD MM YYYY',
                      )
                      : '-'}
                  </ShortText>
                </div>
              </div>
            </div>
          ) : (
            <div css={labelAndValueContainerStyles}>
              <ShortText styles={labelStyles}>worked as</ShortText>
              <ShortText styles={valueStyles}>
                {applicantData.track_records.work_experience.length !== 0
                  ? applicantData.track_records.work_experience[0]?.title
                  : '-'}
              </ShortText>
            </div>
          )}
          <div css={skillsContainerStyles}>
            <ShortText styles={labelStyles}>skills</ShortText>
            <div css={skillPillContainerStyles}>
              {applicantData.skills.map(({ id, label }) => (
                <div key={id} css={skillPillStyles}>
                  <ShortText styles={skillStyles}>{label}</ShortText>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div css={buttonContainerStyles}>
          {jobId !== 'resume' && (
            <div css={applicationDropdownContainerStyles}>
              <Button
                type="button"
                styles={applicationButtonStyles}
                size="XL"
                color="LIGHT_BLUE"
                onClick={handleApplicationDropdownToggle}
              >
                applicant
                <Icon name="arrow-down-s-line" styles={downArrowIconStyles} />
              </Button>
              <nav
                ref={applicationDropdownRef}
                css={applicationDropdownStyles(isApplicationDropdownShow)}
              >
                <ul>
                  {applicationOptions.map(({ label, value }) => (
                    <li key={label}>
                      <button
                        type="button"
                        css={buttonListStyles}
                        onClick={() => handleUpdateJobApplication(applicantData.id, value)}
                      >
                        {label}
                      </button>
                    </li>
                  ))}
                  <div css={dropdownDivider} />
                  {applicationRejectedOptions.map(({ label, value }) => (
                    <button
                      key={value}
                      type="button"
                      css={rejectButtonStyles}
                      onClick={() => handleUpdateJobApplication(applicantData.id, value)}
                    >
                      {label}
                    </button>
                  ))}
                </ul>
              </nav>
            </div>
          )}
          <ExternalLink
            href={`mailto:${contactFinder('email')}`}
            styles={buttonLinkStyles}
          >
            <Button type="button" size="XL" inverted>
              send email
            </Button>
          </ExternalLink>
          <ExternalLink
            href={applicantData.resume?.url}
            styles={buttonLinkStyles}
          >
            <Button type="button" size="XL" color="LIGHT_BLUE" inverted>
              download resume
            </Button>
          </ExternalLink>
        </div>
      </div>
      <div css={[cardStyles, lowerCardStyles]}>
        {applicantData.last_hacktiv_course_track_record && (
          <div css={instructorRemarksCardContainerStyles}>
            <div css={instructorRemarksCardStyles}>
              <ShortText styles={remarkTitleStyles}>
                <Icon name="quill-pen-line" styles={quillIconStyles} />
                &nbsp;remark from instructor
              </ShortText>
              <Text styles={remarkDescriptionStyles}>
                {applicantData.last_hacktiv_course_track_record.description}
              </Text>
            </div>
          </div>
        )}
        <div>
          <ShortText styles={titleStyles}>professional summary</ShortText>
          <Text styles={professionalSummaryStyles}>
            {applicantData.summary || '-'}
          </Text>
        </div>
        <div css={workExperienceAndEducationContainerStyles}>
          <div css={columnStyles}>
            <ShortText styles={titleStyles}>work experience</ShortText>
            {applicantData.track_records.work_experience.map((d) => (
              <ExperienceList key={d.id} data={d} />
            ))}
          </div>
          <div css={columnStyles}>
            <ShortText styles={titleStyles}>education</ShortText>
            {applicantData.track_records.formal_education.map((d) => (
              <ExperienceList key={d.id} data={d} isEducation />
            ))}
            {applicantData.track_records.informal_education.map((d) => (
              <ExperienceList key={d.id} data={d} isEducation />
            ))}
          </div>
          <div css={columnStyles}>
            <ShortText styles={titleStyles}>certificate</ShortText>
            {
              applicantData.track_records.certificate?.map((d) => (
                <CertificateList key={d.id} data={d} />
              ))
            }
          </div>
          <div css={columnStyles}>
            <ShortText styles={titleStyles}>projects</ShortText>
            {applicantData.track_records.project?.map((d) => (
              <ProjectList key={d.id} data={d} />
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

Details.propTypes = {
  applicantData: PropTypes.shape({
    id: PropTypes.number,
    profile_image_url: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    summary: PropTypes.string,
    city: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        channel: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    interests: PropTypes.arrayOf(PropTypes.shape({})),
    resume: PropTypes.shape({
      url: PropTypes.string,
    }),
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    last_batch: PropTypes.shape({
      program_name: PropTypes.string,
      graduation_date: PropTypes.string,
    }),
    batches: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        program_name: PropTypes.string,
        graduation_time: PropTypes.string,
      }),
    ),
    last_hacktiv_course_track_record: PropTypes.shape({
      score: PropTypes.number,
      description: PropTypes.string,
    }),
    track_records: PropTypes.shape({
      work_experience: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          title: PropTypes.string,
          date_from: PropTypes.string,
          date_until: PropTypes.string,
          institution_name: PropTypes.string,
          description: PropTypes.string,
          city: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
          province: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
          country: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
        }),
      ),
      formal_education: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          date_from: PropTypes.string,
          date_until: PropTypes.string,
          institution_name: PropTypes.string,
          field_of_study: PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
          }),
          description: PropTypes.string,
          city: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
          province: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
          country: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
        }),
      ),
      informal_education: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          date_from: PropTypes.string,
          date_until: PropTypes.string,
          institution_name: PropTypes.string,
          field_of_study: PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
          }),
          description: PropTypes.string,
          city: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
          province: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
          country: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
        }),
      ),
      project: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          title: PropTypes.string,
          date_from: PropTypes.string,
          date_until: PropTypes.string,
          description: PropTypes.string,
          attachment_url: PropTypes.string,
          link: PropTypes.string,
        }),
      ),
      certificate: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          date_from: PropTypes.string,
          date_until: PropTypes.string,
          description: PropTypes.string,
        }),
      ),
    }),
  }).isRequired,
};

export default Details;
